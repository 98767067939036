import { useEffect, useState, useMemo } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

import DataTable from '../../containers/DataTable'

import projectAPICall from '../../app/apiCalls/projectAPICall'
import { selectProject, endProjectUpdate } from '../../app/store/actions/projectActions'
import { project_list } from '../../app/apiEndpoints'

const ProjectTable = (props) => {
    const [projects, setProjects] = useState([])

    const tableID = "project"
    const tableFields = ["Project", "Client", "Start Date", "Hours", "Status"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentProjectID)

    const projectUpdate = useSelector((state) => state.performProjectUpdate)
    const dispatch = useDispatch();

    useMemo(() => {
        const getProjects = async () => {
            const data = await projectAPICall(project_list, {'status': props.statusFilter, 'requester': userID, 'company': companyID })
            setProjects(data.projects)
        }
        if(projects.length === 0)
            getProjects()
    }, [userID, companyID, projects, props.statusFilter])

    useEffect(() => {
        if(!projectUpdate || projects.length === 0) return;
        const projIndex = projects.findIndex(project => (project.project_id === rowID));
        projects[projIndex] = projectUpdate
        setProjects(projects)
        dispatch(endProjectUpdate())
    }, [projectUpdate, rowID, projects, dispatch])


    const onClick = (e) => {
        if(!props.showDetailsOnClick) return;
        let pID = e.currentTarget.id
        pID = pID.replace("project_", "")
        props.selectProject(pID)
    }
    
    const tableData = () => {
        if(projects.length === 0) {
            return [];
        } console.log(projects);
        let data = [];
        projects.forEach(project => {
            data.push({'id': project.project_id, 'values': [
                (props.showDetailsOnClick ? project.project_name : `<a href="/projects/${project.project_id}">${project.project_name}</a>`),
                project.company_name,
                project.startDatePretty,
                project.hours,
                projectStatusName(project)
            ]});
        });
        return data
    }

    const projectStatusName = (project) => {
        if(parseInt(project.status) === 1) return "Active";
        else if(project.invoice_id === null || parseInt(project.invoice_id) === 0) return "Pending Invoice";
        else if(parseInt(project.invoice_id) > 0) {
            return (parseInt(project.paid) === 2 ? "Paid" : "Pending Payment");
        }
        return "Unknown"
    }

    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={rowID}  />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectProject: (projectID) => { 
            dispatch(selectProject(projectID))
        },
        endProjectUpdate: () => { 
            dispatch(endProjectUpdate())
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(ProjectTable)