import validations from "../validations";

const validateInput = (title, fieldValue, validate) => {
    let error = ""
    console.log("Validate", title, validate)

    const rules = validate ? validate.split("|") : "";
    if(rules.length === 0) {
        return ""
    }

    for(const rule in rules) {
        const ruleName = rules[rule];
        if(!validations[ruleName]) {console.log(`skip ${ruleName}`); continue }
        const validation = validations[ruleName]
        const isRuleSatisfied =
          ruleName !== "required" && !fieldValue
            ? true
            : validation.rule().test(fieldValue.toString());

        if (!isRuleSatisfied) {
          error = validation.formatter.apply(null, [title]);
        }

        if (error !== "") {
          break;
        }
    }
    return error
}

export default validateInput;