import React, { useState } from "react";
import { connect } from 'react-redux'

import Form from '../containers/Form/Form'
import Input from '../containers/Form/Input';
import Textarea from "../containers/Form/Textarea";
import Button from "../containers/Form/Button";
import loginPageContent from "../containers/loginPageContent";

import siteAPICall from "../app/apiCalls/siteAPICall"
import { contact_submit } from "../app/apiEndpoints";
import { Card } from "react-bootstrap";

function Contact() {
    const [inputValue, setInputValue] = useState({ name: "", email: "", message: "" });
    const [errors, setErrors] = useState({ name: "", email: "", message: "" });
    const { name, email, message } = inputValue;
    const [formMessage, setFormMessage] = useState('')

    const submitContact = async (e) => {
        e.preventDefault();
        let hasErrors = []
        for (const field of Object.entries(errors)) {
            const error = errors[field]
            if(error.length > 0) hasErrors.push(error)
        }
        console.log('errors', errors)
        if(hasErrors) { 
            setFormMessage(hasErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await siteAPICall(contact_submit, { 'name': name, 'email': email, 'message': message })
        console.log(data)

        //if successful, delete from project object
        if(data.error) {
            setFormMessage(data.error)
            return
        } 

        setFormMessage("Thank you for contacting us!")
    }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    return (
        <Form title="Contact Us" description="Having an issue with the site? Let us know" onSubmit={submitContact} >
            <Input
                type="text"
                value={name}
                placeholder="Your Name"
                label="Name"
                name="name"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
            />
            <Input
                type="text"
                value={email}
                placeholder="Your Email"
                label="Email"
                name="email"
                onChange={handleChange}
                onError={handleError}
                validate="email|required"
            />

            <Textarea
                height={100}
                value={message}
                placeholder="Your Message"
                label="Message"
                name="message"
                onChange={handleChange}
                onError={handleError}
                validate="required"
            />
            <Card.Text className="text-danger p-3" dangerouslySetInnerHTML={{ __html: (formMessage) }} />
            <Button
                title="Send Message"
                className="primary"
                center={true}
                includeColumns={true}
            />
        </Form>
    )
}

export default connect(state => ({...state}))(loginPageContent(Contact));
