
//import { connect } from 'react-redux'
//import { startNewRoster, clearRoster } from '../actions/rosterActions'
import { Container } from 'react-bootstrap'
import { SITE_NAME } from '../../app/globals'

import { FaUserCircle, FaEnvelope } from "react-icons/fa";


const LoginHeader = ({ curPage }) => {
    return (
    <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute">
        <Container>
            <div className="navbar-wrapper">
                <a className="navbar-brand" href="/">{SITE_NAME}</a>
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                    <span className="navbar-toggler-bar burger-lines"></span>
                </button>
            </div>
            <div className="collapse navbar-collapse justify-content-end" id="navbar">
                <ul className="navbar-nav">
                    <li className={`nav-item ${curPage === undefined || curPage === "LoginPage" ? 'active' : ''}`}>
                        <a href="/" className="nav-link">
                            <FaUserCircle /> Home
                        </a>
                    </li>
                    <li className={`nav-item ${curPage === "Contact" ? 'active' : ''}`}>
                        <a href="/Contact" className="nav-link">
                            <FaEnvelope /> Contact
                        </a>
                    </li>
                </ul>
            </div>
        </Container>
    </nav>
    )
}

/*
const mapDispatchToProps = (dispatch) => {
    return {
        startNewRoster: () => {
            dispatch(startNewRoster())
        },
        clearRoster: () => {
            dispatch(clearRoster())
        }
    }
}
*/

//export default connect(state => ({isActive: state.isActive, rosterID: state.rosterID}), mapDispatchToProps)(Header);

export default LoginHeader;