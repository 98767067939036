
import { connect, useSelector, useDispatch } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";

import apisAPICall from '../../app/apiCalls/apisAPICall'

import { selectAPI } from '../../app/store/actions/apiActions'

import { SITE_NAME } from '../../app/globals'
import { api_details, api_save } from '../../app/apiEndpoints';
import InvalidPermissions from '../../containers/InvalidPermissions';
import ContentNotFound from '../../containers/ContentNotFound';

const APIForm = (props) => {
    let { apiID } = useParams();

    const [api, setApi] = useState({})
    const [inputValue, setInputValue] = useState({ company: 0, name: "", code: "", version: 1 });
    const [errors, setErrors] = useState({ company: "", name: "", code: "", version: "" });
    const { company, name, code, version } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin
    const newAPI = !api.api_id

    const dispatch = useDispatch();

    useMemo(() => {
        const getAPI = async () => {
            if(parseInt(apiID) > 0) {
                const data = await apisAPICall(api_details, {'apiID': apiID, 'requester': userID, 'company': companyID })
                if(data.api.api_id) {
                    const values = { company: data.api.company_id, name: data.api.api_name, code: data.api.api_code, version: data.api.version }
                    setInputValue(values)
                    setApi(data.api)
                    setLoading(false)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setApi({ missing: true })
                }
            }
        }
        if(parseInt(apiID) > 0 && (api === undefined || api === null || !api.api_id) && loading) {
            getAPI()
        } else if(apiID === "add") {
            setLoading(false)
        }
    }, [apiID, userID, companyID, api, loading])

    const saveAPI = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await apisAPICall(api_save, {
            'apiID': api.api_id ? api.api_id : 0, 
            'requester': userID, 
            'company': company, 
            'name': name, 
            'code': code, 
            'version': version 
        })

        //if successful, delete from project object
        if(data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.api?.api_id) apiID = data.api.api_id
        setApi(data.api)
        
        console.log(props)
        setFormMessage("API has been saved")
        window.history.replaceState(null, SITE_NAME, `/apis/${apiID}`);
        dispatch(selectAPI(apiID))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    if(apiID === "add" && !isSysadmin)
        return <InvalidPermissions />


    if(api.missing)
        return <ContentNotFound contentType="API" />

    return (
        <>
        <Form title={!newAPI ? "Edit API" : "Add API"}  description="" onSubmit={saveAPI} >
            { !loading ?
            <>
            <Select
                value={company}
                label="Company"
                name="company"
                formField="companies"
                useDefault={true}
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="6"
                readOnly={!canEdit}
            />

            <Input
                type="text"
                value={name}
                placeholder="API Name"
                label="API Name"
                name="name"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={code}
                placeholder="API Code"
                label="API Code"
                name="code"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="number"
                value={version}
                placeholder="API Version"
                label="API Version"
                name="version"
                onChange={handleChange}
                onError={handleError}
                validate="numeric|required"
                colSize="3"
                readOnly={!canEdit}
            />
            { canEdit &&
            <FormFooter 
                formButton={{title: "Save API", class: "primary"}} 
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectAPI: (apiID) => { 
            dispatch(selectAPI(apiID))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(pageWithSideMenu(pageContent(withRouter(APIForm)), "API Form"));
