import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'

import CardHeader from '../../containers/CardHeader'
import { Card, Row, Col } from 'react-bootstrap';
import { dashboard } from '../../app/apiEndpoints';
import siteAPICall from '../../app/apiCalls/siteAPICall';

const DashboardClientStatus = () => {
    const [invoices, setInvoices] = useState([])
    const [projects, setProjects] = useState([])

    const userID = useSelector((state) => state.user.id)
    const company = useSelector((state) => state.user.company)
    const isAdmin = useSelector((state) => state.user.isAdmin)

    useMemo(() => {
        const getData = async () => {
            const data = await siteAPICall(dashboard, {'requester': userID, 'company': company.id })
            if(data.invoices?.length > 0) setInvoices(data.invoices)
            if(data.projects?.length > 0) setProjects(data.projects)
        }
        getData()
    }, [userID, company])

    return (
        <>
        <Card>
            {isAdmin ?
            <>
            <CardHeader title={company?.name ? company.name : "Company Status"} description="Below is your Company's status with Rude Development" />
            <Card.Body>
                <Card.Title>Pending Invoices</Card.Title>
                { invoices.length > 0 ?
                invoices.map(invoice => (
                    <Row>
                        <Col md={6}><a href={`/invoices/${invoice.invoice_id}`}>Invoice #{invoice.invoice_no}</a></Col>
                        <Col md={6} className="text-right">Due on {invoice.due_date}</Col>
                    </Row>
                )) 
                : <Card.Text>No pending invoices</Card.Text> 
                }

                <Card.Title className="mt-5">Active Projects</Card.Title>
                { projects.length > 0 ?
                projects.map(project => (
                    <Row>
                        <Col md={6}><a href={`/projects/${project.project_id}`}>{project.project_name}</a></Col>
                        <Col md={6} className="text-right">Started on {project.created_date}</Col>
                    </Row>
                )) 
                : <Card.Text>No active projects</Card.Text> 
                }
            </Card.Body>
            </> 
            :
            <>
            <CardHeader title="Ruudev" description="" />
            <Card.Body>
                <Card.Text>Welcome to Ruudev, the Client Portal for Rude Development</Card.Text>
            </Card.Body>
            </>
            }
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(DashboardClientStatus);
