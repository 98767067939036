import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import clientAPICall from '../../app/apiCalls/clientAPICall'
import { clients_list } from '../../app/apiEndpoints'

const ClientTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "api"
    const tableFields = ["Name", "Client Since", "Active Projects", "Finished Projects"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentClientID)

    useMemo(() => {
        const getData = async () => {
            const data = await clientAPICall(clients_list, {'requester': userID, 'company': companyID })
            setData(data.clients)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.company_id, 'values': [
                (props.showDetailsOnClick ? row.company_name : `<a href="/clients/${row.company_id}">${row.company_name}</a>`),
                row.start_date,
                row.active_projects,
                row.finished_projects
            ]});
        });
        return rows
    }

    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} currentRowID={rowID} />
        </>
    )
}

export default connect(state => ({...state}))(ClientTable)