import React from 'react';
import { Row, Col } from "react-bootstrap";


const pageContentWithDetails = (WrappedComponent, WrappedComponent2) => {
    class ContentWrapper extends React.Component {
        render () {
            return (
                <div className="content">
                    <div className="container-fluid">
                        <Row>
                            <Col md={7}>
                                <WrappedComponent /> 
                            </Col>
                            <Col md={5}>
                                <WrappedComponent2 /> 
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        }
    }

    return ContentWrapper
}

export default pageContentWithDetails;
