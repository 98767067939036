
import { FaElementor, FaTachometerAlt, FaClipboardList, FaDonate, FaDatabase, FaListAlt, FaToolbox, FaUserTie, FaImage, FaUsers } from "react-icons/fa";

export const sideMenuLinks = [
    {
      path: "/",
      name: "Dashboard",
      icon: FaTachometerAlt,
      reqAdmin: false,
      reqSysAdmin: false,
      reqGroup: []
    },
    {
      path: "/projects/",
      name: "Projects",
      icon: FaClipboardList,
      reqAdmin: true,
      reqSysAdmin: false,
      reqGroup: []
    },
    {
      path: "/apis/",
      name: "APIs",
      icon: FaDatabase,
      reqAdmin: true,
      reqSysAdmin: false,
      reqGroup: []
    },
    {
      path: "/banners",
      name: "Banners",
      icon: FaImage,
      reqAdmin: true,
      reqSysAdmin: false,
      reqGroup: []
    },
    {
      path: "/landers",
      name: "Landing Pages",
      icon: FaElementor,
      reqAdmin: true,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/tools/",
      name: "Tools",
      icon: FaToolbox,
      reqAdmin: true,
      reqSysAdmin: false,
      reqGroup: [ "3", "5" ]
    },
    {
      path: "/reports/",
      name: "Reports",
      icon: FaListAlt,
      reqAdmin: true,
      reqSysAdmin: false,
      reqGroup: [ "4", "5" ]
    },
    {
      path: "/invoices/",
      name: "Invoices",
      icon: FaDonate,
      reqAdmin: true,
      reqSysAdmin: false,
      reqGroup: []
    },
    {
      path: "/clients/",
      name: "Clients",
      icon: FaUserTie,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    },
    {
      path: "/users/",
      name: "Users",
      icon: FaUsers,
      reqAdmin: false,
      reqSysAdmin: true,
      reqGroup: []
    }
  ];