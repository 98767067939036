
import { useState } from 'react'
import ConfirmDialog from '../../containers/ConfirmDialog';
import { FaMinusCircle } from "react-icons/fa";
import { Row, Col } from 'react-bootstrap'

const ProjectUpdate = (props) => {

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const deleteUpdate = () => {
        props.onClick(props.update.update_id)
    }

    return (
        <>
            <Row>
                <Col md={9}>
                    {props.update.note}
                </Col>
                <Col md={3} className="text-right">
                    {props.canEdit ? 
                    <span className="hover" onClick={() => setShowConfirmDelete(true)}><FaMinusCircle /></span> : ""}
                </Col>
            </Row>
            <Row className="mt-3 text-secondary">
                <Col md={4}>{props.update.hours} hours</Col>
            	<Col md={8} className="text-right">{props.update.date}</Col>
            </Row>
            <hr/>
            <ConfirmDialog
                show={showConfirmDelete}
                title="Delete Project Update" message="Are you sure you wish to delete this Update?"
                onClose={() => { setShowConfirmDelete(false) }}
                onConfirm={deleteUpdate}
            />
        </>
    )
}

export default (ProjectUpdate);
