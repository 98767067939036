import { useEffect, useMemo, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

import DataTable from '../../containers/DataTable'

import invoiceAPICall from '../../app/apiCalls/invoiceAPICall'
import { selectInvoice, endInvoiceUpdate } from '../../app/store/actions/invoiceActions'
import { invoices_list } from '../../app/apiEndpoints'

const InvoiceTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "invoice"
    const tableFields = ["Invoice", "Client", "Invoice Date", "Total Hours", "Total $", "Status"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentInvoiceID)

    const invoiceUpdate = useSelector((state) => state.performInvoiceUpdate)
    const dispatch = useDispatch();

    const onClick = (e) => {
        if(!props.showDetailsOnClick) return;
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        props.selectInvoice(rowID)
    }

    useMemo(() => {
        const getData = async () => {
            const data = await invoiceAPICall(invoices_list, {'requester': userID, 'company': companyID })
            setData(data.invoices)
        }
        getData()
    }, [userID, companyID])

    useEffect(() => {
        if(!invoiceUpdate || data.length === 0) return;
        const invIndex = data.findIndex(invoice => (invoice.invoice_id === rowID));
        data[invIndex] = invoiceUpdate
        setData(data)
        dispatch(endInvoiceUpdate())
    }, [invoiceUpdate, rowID, data, dispatch])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.invoice_id, 'values': [
                (props.showDetailsOnClick ? `#${row.invoice_no}` : `<a href="/invoices/${row.invoice_id}">#${row.invoice_no}</a>`),
                row.company_name,
                row.invoice_date,
                row.total_hours,
                `$${row.total_billed}`,
                invoiceStatusName(parseInt(row.status))
            ]});
        });
        return rows
    }

    const invoiceStatusName = (status) => {
        switch(status) {
            case 1: return "Waiting Payment";
            case 2: return "Paid";
            default:
        }
        return "Pending";
    }

    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={rowID} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectInvoice: (id) => { 
            dispatch(selectInvoice(id))
        },
        endInvoiceUpdate: () => { 
            dispatch(endInvoiceUpdate())
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(InvoiceTable)