import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'
import ConfirmDialog from '../../../containers/ConfirmDialog';

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_cam_links, tool_cam_links_link, tool_cam_links_unlink } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col, FormControl } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'

const CamModelLinkTool = (props) => {
    const toolID = 7
    const siteID = 1
    const [tool, setTool] = useState({})
    const [campaignid, setCampaignid] = useState('')
    const [source, setSource] = useState(1)
    const [models, setModels] = useState('')
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [campaigns, setCampaigns] = useState([])

    const tableID = "result"
    const tableFields = ["Campaign ID", "Model Name", "Action"]

    const [curModel, setCurModel] = useState('')
    const [showConfirmUnlink, setShowConfirmUnlink] = useState(false)

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_cam_links, { 'requester': userID, 'site': siteID })
                setCampaigns(data.campaigns)
            }
        }
        getDetails()
    }, [userID, siteID])

    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)
            const data = await toolsAPICall(tool_cam_links_link, { 'campaignid': campaignid, 'models': models, 'site': siteID, 'source': source })
            if(data.error) {
                setFormMessage(data.error)
            } 
            else if(data.campaigns) {
                setCampaigns(data.campaigns)
                setFormMessage("Campaign Updates complete")
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if(parseInt(campaignid) > 0 && updating && !processing) {
            performUpdate()
        }
    }, [campaigns, campaignid, models, source, siteID, updating, processing])

    const tableData = () => {
        if(campaigns.length === 0) {
            return [];
        } 
        //console.log('data', campaigns)
        let rows = []; let prevID = 0;
        campaigns.forEach(row => {
            rows.push({'id': `${row.name}|_|${row.id}`, 'deleteFnc': confirmUnlink, 'values': [
                (prevID === row.id ? '' : `<span class=${row.campaign_online === "1" ? "online-dot" : "offline-dot"}></span> ${row.id}`),
                `<span class=${row.online === "1" ? "online-dot" : "offline-dot"}></span> ${row.name}`,
            ]});
            prevID = row.id
        });
        return rows
    }

    const confirmUnlink = (e) => {
        e.preventDefault()
        console.log(e)
        let rowID = e.target.id
        rowID = rowID.replace(`delete_`, "")
        let rowIDs = rowID.split("|_|")
        console.table(rowIDs)
        if(rowIDs?.length === 2) {
            setCurModel(rowIDs[0])
            setCampaignid(rowIDs[1])
            setShowConfirmUnlink(true)
        }
    }

    const updateCampaigns = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!campaignid || isNaN(parseInt(campaignid)) || parseInt(campaignid) < 0) {
            setFormMessage("Please enter a Campaign ID")
            return
        }
        if(!models || models?.length === 0) {
            setFormMessage("Please enter at least one Model Name")
            return
        }

        setUpdating(true)
    }


    const unlinkCampaign = async (e) => {
        setShowConfirmUnlink(false)
        
        if(curModel.length === 0) return;
        const data = await toolsAPICall(tool_cam_links_unlink, {
            'requester': userID, 
            'company': companyID, 
            'model': curModel,
            'campaignid': campaignid,
            'site': siteID
        })

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        if(data.campaigns) {
            setCampaigns(data.campaigns)
        }
    }

    if(!tool) return <></>

    return (
        <>
        <Row>
            <Col md={6}>
                <Form title={tool.tool_name} description={tool.description} onSubmit={updateCampaigns}>  
                <Card.Body>
                    <Row>
                        <Col md={4} className="control-label col-form-label">Campaign ID</Col>
                        <Col md={5} sm={8}>
                            <FormControl aria-label="Campaign ID" type="text" name="campaignid" id="campaignid" onChange={(e) => setCampaignid(e.target.value)} readOnly={updating} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Source</Col>
                        <Col md={5} sm={8}>
                            <select id="source" name="source" className="form-control" value={source} readOnly={updating} onChange={(e) => setSource(e.target.value)}>
                                <option value="1">Banners</option>
                                <option value="2">Popups</option>
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Model Names</Col>
                        <Col md={5}>
                            <textarea className="form-control" id="models" name="models" readOnly={updating} style={{height: 300 }} value={models} onChange={(e) => setModels(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center pt-5">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title="Update Models" className="info" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
                </Form>
                <ConfirmDialog
                    show={showConfirmUnlink}
                    title="Unlink Campaign" message="Are you sure you wish to unlink this campaign?"
                    onClose={() => { setShowConfirmUnlink(false) }}
                    onConfirm={unlinkCampaign}
                />
            </Col>
            <Col md={6}>
                <Card>
                    <CardHeader title="Existing Campaign Links" description="Saved links between Campaign Ids and Models" />
                    <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} />
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(CamModelLinkTool), "Cam Model Campaign Links"));