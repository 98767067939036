import React, { useEffect } from 'react';
import { connect } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom';
import { routes } from './app/routes'
import RouteWithSubRoutes from './components/Global/RouteWithSubRoutes'

import LoginPage from './components/Login/LoginPage';
import { handleLogout } from './app/store/actions/loginActions'

function App(props) {

  useEffect(() => {
    if(window.location.pathname === "/logout/") {
      props.handleLogout()
      window.history.replaceState(null, 'Ruudev', '/');
    }
  })

  if(!props.token && window.location.pathname !== "/Contact") 
    return <LoginPage />

  return (
    <BrowserRouter>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </BrowserRouter>
  );
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleLogout: () => { 
            dispatch(handleLogout())
        }
    }
}

export default connect(state => ({...state}), mapDispatchToProps)(App);