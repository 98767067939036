
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import AdminDashboard from './AdminDashboard'
import UserDashboard from './UserDashboard'

const DashboardSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    return (
        <>
        {isSysadmin ? <AdminDashboard /> : <UserDashboard />}
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(DashboardSection), "Dashboard"));
