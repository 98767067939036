import React from 'react';
import Moment from 'moment';

import './print.css';

export class InvoicePrintView extends React.PureComponent {
    invoiceDate = () => {
        Moment.locale('en');
        return Moment(this.props.invoice.invoice_date).format('MMMM Do, Y') 
    }

    dueDate = () => {
        Moment.locale('en');
        return Moment(this.props.invoice.invoice_date).add(1, 'M').format('MMMM Do, Y') 
    }

    companyInfo = () => {
        const invoice = this.props.invoice
        return `
            ${invoice.company_name}<br/>
            ${invoice.address_line_1?.length > 0 ? `${invoice.address_line_1}<br/>` : ''}
            ${invoice.address_line_2?.length > 0 ? `${invoice.address_line_2}<br/>` : ''}
            ${invoice.contact_email?.length > 0 ? `${invoice.contact_email}<br/>` : ''}
            ${invoice.contact_phone?.length > 0 ? `${invoice.contact_phone}<br/>` : ''}
            `
    }

    render() {
        const invoice = this.props.invoice
        return (
            <>
            <div className="invoice-box">
                <table cellPadding="0" cellSpacing="0">
                <tbody>
                    <tr className="top">
                        <td colSpan="2">
                            <table>
                                <tbody>
                                <tr>
                                    <td className="title">
                                        Rude Development
                                    </td>
                                    <td>
                                        Invoice #: {invoice.invoice_no}<br />
                                        Created: {this.invoiceDate()}<br/>
                                        Due: {this.dueDate()}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr className="information">
                        <td colSpan="2">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        Alex Rude<br/>
                                        5120 N Ravine Ln<br/>
                                        Fair Oaks, CA 95628<br/>
                                        alex@alexrude.com<br/>
                                        (916) 705-1629
                                    </td>
                                    <td dangerouslySetInnerHTML={{ __html: this.companyInfo() }} />
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr className="heading">
                        <td>Item</td>
                        <td>Price</td>
                    </tr>
                    {invoice?.t_projects ? invoice.t_projects.map((project,p) => (
                    <tr className="item" key={p}>
                        <td>
                            {project.project_name} ({project.hours} hour{project.hours === 1.00 ? '' : 's'})
                        </td>
                        <td>
                            ${(project.hours * invoice.hourly_rate).toFixed(2)}
                        </td>
                    </tr>
                    )) : null}
                    <tr className="total">
                        <td>&nbsp;</td>
                        <td>
                        Total: ${invoice.total_billed}
                        </td>
                    </tr>
                    <tr className="information" style={{ marginTop: 10 }}>
                        <td colSpan ="2">
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        Make all checks payable to Alex Rude <br/>
                                        Federal EIN: 80-0492274
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </>
        )
    }
}