
const validations = {
    required : {
      rule    : () => /\S/,
      formatter (fieldName) {
        return `${fieldName} is required.`
      }
    },
    numeric : {
      rule : () => /^\d+$/,
      formatter (fieldName) {
        return `${fieldName} should contain only numbers.`
      }
    },
    decimal : {
      rule : () => /^\d+(\.\d{1,2})?$/,
      formatter (fieldName) {
        return `${fieldName} should be in numeric format.`
      }
    },
    name : {
      rule : () => /^[a-zA-Z]+(?:(?:\. |[-'\s])[a-zA-Z]+)*$/,
      formatter (fieldName) {
        return `${fieldName} is not a valid name.`
      }
    },
    company : {
      rule : () => /^[a-zA-Z ,.\-']{2,30}$/,
      formatter (fieldName) {
        return `${fieldName} is not a valid name.`
      }
    },
    email : {
      rule : () => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      formatter (fieldName) {
        return `${fieldName} is not a valid email address.`
      }
    },
    phone : {
      rule : () => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
      formatter (fieldName) {
        return `${fieldName} is not a valid phone number.`
      }
    },
    address : {
      rule : () => /[a-zA-Z0-9&,.\-+/\s]+/,
      formatter (fieldName) {
        return `${fieldName} is not a valid address.`
      }
    },
    date : {
      rule : () => /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{4}$/,
      formatter (fieldName) {
        return `${fieldName} is not a valid date.`
      }
    },
    datetime : {
      rule : () => /^(([0]?[1-9]|1[0-2])\/([0-2]?[0-9]|3[0-1])\/[1-2]\d{3}) (20|21|22|23|[0-1]?\d{1}):([0-5]?\d{1})$/,
      formatter (fieldName) {
        return `${fieldName} is not a valid date time.`
      }
    },
    url : {
      rule : () => /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/,
      formatter (fieldName) {
        return `${fieldName} is not a valid URL.`
      }
    },
  }
  
  export default validations
