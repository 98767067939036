import ProjectTable from '../Projects/ProjectTable'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap';

const AdminDashboard = () => {
    const title = "Active Projects";
    const subtitle = "Listing of active Client Projects"
    const headerAction = {'path': "projects/add", 'icon':FaPlus} 

    return (
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <ProjectTable statusFilter={1} />
        </Card>
    )
}

export default AdminDashboard;