import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_tj_pause } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { Card, Row, Col } from 'react-bootstrap'
import Form from '../../../containers/Form/Form'
import { tableTypes } from '../../../app/settings'

const TJPauseTool = (props) => {
    const toolID = 5
    const [tool, setTool] = useState({})
    const [action, setAction] = useState('pause')
    const [source, setSource] = useState(1)
    const [campaignids, setCampaignids] = useState('')
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [campaigns, setCampaigns] = useState([])
    const [curCampaign, setCurCampaign] = useState(-1)

    const tableID = "result"
    const tableFields = ["Campaign ID", "Campaign Name", "Status", "Progress"]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const performUpdate = async () => {
            if(campaigns[curCampaign]) {
                const item = campaigns[curCampaign]
                const data = await toolsAPICall(tool_tj_pause, { 'campaignid': item.id, 'action': action, 'source': source })
                if(data.error) {
                    setFormMessage(data.error)
                } 
                else if(data.campaign) {
                    campaigns[curCampaign] = data.campaign
                    setCampaigns(campaigns)
                }
                setCurCampaign(curCampaign+1)
            } else {
                setFormMessage("Campaign Updates complete")
            }
        }
        console.log('update', curCampaign, campaigns)
        if(curCampaign >= 0) {
            performUpdate()
        }
    }, [campaigns, curCampaign, action, source])

    const tableData = () => {
        if(campaigns.length === 0) {
            return [];
        } 
        console.log('data', campaigns)
        let rows = [];
        campaigns.forEach(row => {
            rows.push({'id': row.id, 'values': [
                row.id,
                row.name,
                row.status,
                row.progress || "Pending"
            ]});
        });
        return rows
    }

    const pauseCampaigns = (e) => {
        e.preventDefault()
        setFormMessage("")

        // split rows and remove duplicates
        let ids = campaignids.split("\n")
        ids = ids.filter(function(item, pos) {
            return ids.indexOf(item) === pos;
        })

        // convert to objects
        const newCampaigns = ids.reduce((camps, cId) => {
            if(!camps.includes({'id': cId})) camps.push({'id': cId})
            return camps
        }, [])

        setUpdating(true)
        setCampaigns(newCampaigns)
        setCurCampaign(0)
    }


    if(!tool) return <></>

    return (
        <>
        <Row>
            <Col md={6}>
                    <Form title={tool.tool_name} description={tool.description} onSubmit={pauseCampaigns}>    
                    <Card.Body>                
                        <Row>
                            <Col md={4} className="control-label col-form-label">Action</Col>
                            <Col md={3}>
                                <select className="form-control" id="action" name="action" value={action} readOnly={updating} onChange={(e) => setAction(e.target.value)}>
                                    <option value="pause">Pause</option>
                                    <option value="resume">Activate</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="control-label col-form-label">Source</Col>
                            <Col md={3}>
                                <select id="source" name="source" className="form-control" value={source} readOnly={updating} onChange={(e) => setSource(e.target.value)}>
                                    <option value="1">Banners</option>
                                    <option value="2">Popups</option>
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} className="control-label col-form-label">Campaign IDs</Col>
                            <Col md={5}>
                                <textarea className="form-control" id="campaignids" name="campaignids" readOnly={updating} style={{height: 200 }} value={campaignids} onChange={(e) => setCampaignids(e.target.value)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <p>{formMessage}</p>
                                {!updating ? <button id="tool-submit" className="btn btn-fill btn-info">Update Campaigns</button> : null}
                            </Col>
                        </Row>
                    </Card.Body>
                    </Form>
            </Col>
            <Col md={6}>
                <Card>
                    <CardHeader title="Update History" description="Campaigns will appear here as they are updated" />
                    <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} />
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(TJPauseTool), "Traffic Junky Bulk Campaign Pausing"));
