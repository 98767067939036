import { useState } from 'react'
import { connect, useSelector } from 'react-redux'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";

import projectAPICall from '../../app/apiCalls/projectAPICall'
import { project_add_update } from '../../app/apiEndpoints'

const ProjectUpdateForm = (props) => {
    const [inputValue, setInputValue] = useState({ note: "", hours: "" });
    const [errors, setErrors] = useState({ note: "", hours: "" });
    const { note, hours } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const saveUpdate = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        //call API to save
        console.log('save update', props.curProjectID)
        const data = await projectAPICall(project_add_update, {'projectID': props.curProjectID, 'requester': userID, 'company': companyID, note, hours })

        //if successful, delete from project object
        if(!data.success || data.error) {
            setFormMessage(data.error)
            return
        } 

        if(data?.update) {
            props.onSubmit(data.update, data.hours)
        }

        console.log('saveupdate')
        setInputValue({ note: "", hours: "" });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    return (
        <Form title="New Update" description="" onSubmit={saveUpdate} >
            <Input
                type="text"
                value={note}
                placeholder="Update"
                label="Update"
                name="note"
                onChange={handleChange}
                onError={handleError}
                validate="required"
                colSize="9"
            />
            <Input
                type="number"
                value={hours}
                placeholder="Hours"
                label="Hours"
                name="hours"
                onChange={handleChange}
                onError={handleError}
                validate="decimal|required"
                colSize="4"
            />
            <FormFooter 
                formButton={{title: "Save Update", class: "primary"}}
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} />
        </Form>
    )
}

export default connect(state => ({...state}))(ProjectUpdateForm);