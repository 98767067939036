import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../../containers/pageWithSideMenu'
import pageContent from '../../../containers/pageContent'

import CardHeader from '../../../containers/CardHeader'
import Form from '../../../containers/Form/Form'
import Button from '../../../containers/Form/Button'

import toolsAPICall from '../../../app/apiCalls/toolsAPICall'
import { tool_details, tool_subpid_list, tool_subpid_save } from "../../../app/apiEndpoints"
import DataTable from "../../../containers/DataTable";
import { FaPlus } from "react-icons/fa";
import { Card, Row, Col, FormControl } from 'react-bootstrap'
import { tableTypes } from '../../../app/settings'

const SubpidTool = (props) => {
    const toolID = 9
    const [tool, setTool] = useState({})
    const [id, setId] = useState(0)
    const [routine, setRoutine] = useState('')
    const [paths, setPaths] = useState(Array(10).fill(0))
    const [path1, setPath1] = useState(0)
    const [path2, setPath2] = useState(0)
    const [path3, setPath3] = useState(0)
    const [path4, setPath4] = useState(0)
    const [path5, setPath5] = useState(0)
    const [path6, setPath6] = useState(0)
    const [path7, setPath7] = useState(0)
    const [path8, setPath8] = useState(0)
    const [path9, setPath9] = useState(0)
    const [path10, setPath10] = useState(0)
    const [subpids, setSubpids] = useState('')
    const [formMessage, setFormMessage] = useState('')
    const [updating, setUpdating] = useState(false)
    const [processing, setProcessing] = useState(false)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const [pathOptions, setPathOptions] = useState([])
    const [routines, setRoutines] = useState([])

    const tableID = "result"
    const tableFields = ["Routine", "Paths", "Subpids"]

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_details, {'toolID': toolID, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolID, userID, companyID])

    useEffect(() => {
        const getDetails = async () => {
            if(toolID > 0) {
                const data = await toolsAPICall(tool_subpid_list, { 'requester': userID })
                setRoutines(data.routines)
                setPathOptions(data.options)
            }
        }
        getDetails()
    }, [userID])


    useEffect(() => {
        const performUpdate = async () => {
            setProcessing(true)
            let pathIds = [path1,path2,path3,path4,path5,path6,path7,path8,path9,path10]

            const data = await toolsAPICall(tool_subpid_save, { 'id': id, 'routine': routine, 'paths': pathIds, 'subpids': subpids })
            if(data.error) {
                setFormMessage(data.error)
            } 
            else if(data.routines) {
                setRoutines(data.routines)
                setFormMessage("Routine Updates complete")
            }
            setUpdating(false)
            setProcessing(false)
        }
        
        if((routine).length > 0 && updating && !processing) {
            performUpdate()
        }
    }, [routines, id, routine, path1,path2,path3,path4,path5,path6,path7,path8,path9,path10, subpids, updating, processing])

    const tableData = () => {
        if(routines.length === 0) {
            return [];
        } 
        //console.log('data', campaigns)
        let rows = [];
        routines.forEach(row => {
            rows.push({'id': `${row.routineid}`, 'values': [
                row.routine,
                row.pathstr,
                row.subpidstr
            ]});
        });
        return rows
    }

    const viewRoutine = (e) => {
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        //look for the subpid in array
        let r = routines.find((element) => {
            return element.routineid === rowID;
          })
        if(r === undefined) {
            return
        }
        setId(r['routineid'])
        setRoutine(r['routine'])
        setPaths(Array(10).fill({'pathid':0}))
        setPath1(0)
        setPath2(0)
        setPath3(0)
        setPath4(0)
        setPath5(0)
        setPath6(0)
        setPath7(0)
        setPath8(0)
        setPath9(0)
        setPath10(0)
        setSubpids(r['subpids'])

        if(r['paths'].length > 0) {
            r['paths'].forEach((row, rid) => {
                paths[rid] = row
                console.log(rid)
                if(rid === 0) setPath1(row.pathid)
                else if(rid === 1) setPath2(row.pathid)
                else if(rid === 2) setPath3(row.pathid)
                else if(rid === 3) setPath4(row.pathid)
                else if(rid === 4) setPath5(row.pathid)
                else if(rid === 5) setPath6(row.pathid)
                else if(rid === 6) setPath7(row.pathid)
                else if(rid === 7) setPath8(row.pathid)
                else if(rid === 8) setPath9(row.pathid)
                else if(rid === 9) setPath10(row.pathid)
            });
            setPaths(paths)
        }
    }

    const addRoutine = (e) => {
        setId(0)
        setRoutine("")
        setPaths(Array(10).fill({'pathid':0}))
        setPath1(0)
        setPath2(0)
        setPath3(0)
        setPath4(0)
        setPath5(0)
        setPath6(0)
        setPath7(0)
        setPath8(0)
        setPath9(0)
        setPath10(0)
        setSubpids("")
        console.log(paths)
    }

    const updateRoutine = (e) => {
        e.preventDefault()
        setFormMessage("")

        if(!routine) {
            setFormMessage("Please enter a Routine Name")
            return
        }
        // let pathFound = false
        // paths.forEach(row => {
        //     if(row > 0) {
        //         pathFound = true
        //     }
        // });
        // if(!pathFound) {
        //     setFormMessage("Please select at least one Path to use")
        //     return
        // }
        if(!subpids) {
            setFormMessage("Please enter at least one Subpid")
            return
        }

        setUpdating(true)
    }
    
    /*
    const updatePaths = (path, value) => {
        paths[path] = value
        setPaths(paths)
        console.table(paths)
    }
    */

    if(!tool) return <></>
    return (
        <>
        <Row>
            <Col md={12}>
                <Card>
                    <CardHeader title="Existing Subpids" description="Current Routines being used on Smart Landers" action={{'function': addRoutine, 'icon':FaPlus}} />
                    <DataTable tableID={tableID} fieldNames={tableFields} dataRows={tableData()} tableSettings={tableTypes.None} onClick={viewRoutine} hover={true} />
                </Card>
            </Col>
        </Row>
            <Form title={tool.tool_name} description={tool.description} onSubmit={updateRoutine}> 
        <Row>
            <Col md={6}> 
                <Card.Body>
                    <Row>
                        <Col md={4} className="control-label col-form-label">Routine Name</Col>
                        <Col md={8} sm={8}>
                            <FormControl aria-label="Routine Name" type="text" name="routine" id="routine" value={routine} onChange={(e) => setRoutine(e.target.value)} readOnly={updating} />
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #1</Col>
                        <Col md={8} sm={8}>
                            <select id="path_1" className="form-control" value={path1} readOnly={updating} onChange={(e) => setPath1(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #2</Col>
                        <Col md={8} sm={8}>
                            <select id="path_2" className="form-control" value={path2} readOnly={updating} onChange={(e) => setPath2(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #3</Col>
                        <Col md={8} sm={8}>
                            <select id="path_3" className="form-control" value={path3} readOnly={updating} onChange={(e) => setPath3(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #4</Col>
                        <Col md={8} sm={8}>
                            <select id="path_4" className="form-control" value={path4} readOnly={updating} onChange={(e) => setPath4(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #5</Col>
                        <Col md={8} sm={8}>
                            <select id="path_5" className="form-control" value={path5} readOnly={updating} onChange={(e) => setPath5(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #6</Col>
                        <Col md={8} sm={8}>
                            <select id="path_6" className="form-control" value={path6} readOnly={updating} onChange={(e) => setPath6(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #7</Col>
                        <Col md={8} sm={8}>
                            <select id="path_7" className="form-control" value={path7} readOnly={updating} onChange={(e) => setPath7(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #8</Col>
                        <Col md={8} sm={8}>
                            <select id="path_8" className="form-control" value={path8} readOnly={updating} onChange={(e) => setPath8(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #9</Col>
                        <Col md={8} sm={8}>
                            <select id="path_9" className="form-control" value={path9} readOnly={updating} onChange={(e) => setPath9(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Priority #10</Col>
                        <Col md={8} sm={8}>
                            <select id="path_10" className="form-control" value={path10} readOnly={updating} onChange={(e) => setPath10(e.target.value)}>
                                <option value="0">-- Not Set --</option>
                                {pathOptions.map((option, o) => (
                                <option value={option.pathid} key={`o_${o}`}>{option.type}: {option.path}</option>
                                ))}
                            </select>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center pt-5">
                            <Card.Text>{formMessage}</Card.Text>
                            {!updating ? <Button title="Update Routine" className="info" /> : null}
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
            <Col md={6}> 
                <Card.Body>
                    <Row className="pt-2">
                        <Col md={4} className="control-label col-form-label">Subpids</Col>
                        <Col md={8}>
                            <textarea className="form-control" id="subpids" name="subpids" readOnly={updating} style={{height: 300 }} value={subpids} onChange={(e) => setSubpids(e.target.value)} />
                        </Col>
                    </Row>
                </Card.Body>
            </Col>
        </Row>
            </Form>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(SubpidTool), "Smart Lander Subpid Management"));