
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContentWithDetails from '../../containers/pageContentWithDetails'

import ReportTable from './ReportTable'
import ReportDetails from './ReportDetails'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap'

const ReportSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Client Reports";
    const subtitle = "Listing of all Client Reports"
    const headerAction = isSysadmin ? {'path': "/reports/add", 'icon':FaPlus} : {}

    return (
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <ReportTable showDetailsOnClick={true} />
        </Card>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContentWithDetails(ReportSection, ReportDetails), "Reports"));
