import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'

import { Card, FormGroup, Row, Col } from 'react-bootstrap';
import CardHeader from '../../containers/CardHeader'

import Input from '../../containers/Form/Input'
import Select from '../../containers/Form/Select'
import Button from '../../containers/Form/Button'

import reportAPICall from '../../app/apiCalls/reportAPICall'
import { report_form_fields } from '../../app/apiEndpoints'
import Moment from 'moment';

import generateReport from "../../app/functions/generateReport"
import { CSVLink } from "react-csv";

const DashboardShortcuts = () => {
    const [inputValue, setInputValue] = useState({ reportID: 0, startDate: new Date(Moment().add(-1, 'months')), endDate: new Date(), source: 1 });
    //const [errors, setErrors] = useState({ reportID: "", startDate: "", endDate: "", source: "" });
    const { reportID, startDate, endDate, source } = inputValue;

    const [loadingReports, setLoadingReports] = useState(true)
    const [report, setReport] = useState([])
    const [reports, setReports] = useState([])

    const [csv, setCsv] = useState({})
    const [genStatus, setGenStatus] = useState(0)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    
    useMemo(() => {
        const getData = async () => {
            const data = await reportAPICall(report_form_fields, {'requester': userID, 'company': companyID })
            setReports(data.reports)
            setLoadingReports(false)
        }
        getData()
    }, [userID, companyID])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));

        if(name === "reportID") {
            if(value === 0) {
                setReport({})
            }
            const newReport = reports.find((report) => report.ID === value)
            setReport(newReport)
        }
    };
    
    const handleError = (field, error) => {
          //setErrors((prev) => ({
          //    ...prev,
          //    [field]: error,
          //}));
    };

    const exportReport = async (e) => {
        e.preventDefault()
        setGenStatus(1)

        const csvData = await generateReport(report, Moment(startDate).format('MM/DD/YYYY'), Moment(endDate).format('MM/DD/YYYY'), source)
        if(csvData.file?.length > 0) {
            setCsv(csvData)
            setGenStatus(2)
        }
    }

    const resetReport = (e) => {
        e.preventDefault()
        setGenStatus(0)
        setCsv({})
    }

    return (
        <Card>
            <CardHeader title="Quick Report" description="Generate a report" />
            <Card.Body>
                {!loadingReports ?
                    reports.length === 0 ? <p>No Reports Available</p> :
                <>
                <Select
                    value={reportID}
                    label="Report"
                    name="reportID"
                    formValues={reports}
                    useDefault={true}
                    onChange={handleChange}
                    onError={handleError}
                    validate="numeric|required"
                    colSize="6"
                    readOnly={genStatus > 0}
                />
                {parseInt(report?.ID) === 2 &&
                <Select
                    value={source}
                    label="Source"
                    name="source"
                    formValues={[{ID: 1, NAME: "Banners"}, {ID: 2, NAME: "Popups"}]}
                    useDefault={false}
                    onChange={handleChange}
                    onError={handleError}
                    validate="numeric|required"
                    colSize="6"
                    readOnly={genStatus > 0}
                /> }
                {parseInt(report?.has_date_range) === 1 &&
                <>
                <Input
                    type="date"
                    value={startDate}
                    placeholder="Start Date"
                    label="Start Date"
                    name="startDate"
                    onChange={handleChange}
                    onError={handleError}
                    validate="date|required"
                    colSize="6"
                    readOnly={genStatus > 0}
                />
                <Input
                    type="date"
                    value={endDate}
                    placeholder="End Date"
                    label="End Date"
                    name="endDate"
                    onChange={handleChange}
                    onError={handleError}
                    validate="date|required"
                    colSize="6"
                    readOnly={genStatus > 0}
                />
                </> }
                <FormGroup as={Row}>
                    <Col md={12} className="text-center">
                    {genStatus === 0 && report.ID &&
                    <Button 
                        title="Export Report"
                        className="primary"
                        onClick={exportReport}
                    /> }
                    {genStatus === 1 && <small>Generating Report...</small> }
                    {genStatus === 2 && csv.file && 
                        <CSVLink 
                        data={csv.data}
                        filename={csv.file}
                        className="btn btn-success"
                        target="_blank">Download Report</CSVLink> } { }
                    {genStatus === 2 &&
                    <Button 
                        title="Reset"
                        className="secondary"
                        onClick={resetReport}
                    /> }
                    </Col>
                </FormGroup>

                </> : "Loading Reports..." }
            </Card.Body>
        </Card>
    )
}

export default connect(state => ({...state}))(DashboardShortcuts);
