import { Card } from 'react-bootstrap';

const InvalidPermissions = () => {
    return (
        <Card>
            <Card.Body>
                <Card.Title>Invalid Permissions</Card.Title>
                <Card.Text>You do not have permissions to access this content</Card.Text>
            </Card.Body>
        </Card>
    )
}

export default InvalidPermissions;
