
import { connect, useSelector } from 'react-redux'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import bannerAPICall from '../../app/apiCalls/bannerAPICall'

import CardHeader from '../../containers/CardHeader'
import DataTable from '../../containers/DataTable'

import { FaEdit } from "react-icons/fa";
import { banner_details } from '../../app/apiEndpoints';
import { Card } from 'react-bootstrap';

const BannerView = (props) => {
    const [banner, setBanner] = useState({})
    let { bannerID } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    let title = banner ? banner.name : "Banner";
    let subtitle = banner ? banner.company_name : ""
    const headerAction = banner && isSysadmin ? {'path': `/banners/${bannerID}`, 'icon':FaEdit} : {}

    const tableID = "banner"
    const tableFields = ["URL", "Client", "Width", "Height"]

    useMemo(() => {
        const getBanner = async () => {
            console.log("bannerid", bannerID)
            if(bannerID > 0) {
                const data = await bannerAPICall(banner_details, {'bannerID': bannerID, 'requester': userID, 'company': companyID })
                setBanner(data.banner)
            }
        }
        getBanner()
    }, [bannerID, userID, companyID])

    const tableData = () => {
        if(banner.length === 0) {
            return [];
        } 
        return [{'id': bannerID, 'values': [banner.url, banner.company_name, banner.width, banner.height]}]
    }

    const exitURLData = () => {
        if(banner.length === 0 || !banner.exit_url) {
            return [];
        } 
        return [{'id': bannerID, 'values': [banner.exit_url]}]
    }

    if(!banner) return <></>

    return (
        <>
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <DataTable hover={false} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} />
        </Card>
        {banner.exit_url ? 
        <Card>
            <CardHeader title="Custom Exit URL" />
            <DataTable hover={false} tableID="exitURL" fieldNames={["Custom Exit URL"]} dataRows={exitURLData()} />
        </Card> : ''}
        <Card>
            <CardHeader title="Live Preview" />
            <Card.Body>
                <iframe width={banner.width} height={banner.height} title={banner.name} frameBorder="0" scrolling="0" src={banner.url}></iframe>
            </Card.Body>
        </Card>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(BannerView), "Banners"));
