
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import ClientTable from './ClientTable'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap'
import InvalidPermissions from '../../containers/InvalidPermissions'

const ClientSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Clients";
    const subtitle = "Listing of all Clients"
    const headerAction = isSysadmin ? {'path': "/clients/add", 'icon':FaPlus} : {}

    if(!isSysadmin) 
        return <InvalidPermissions />
        
    return (
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <ClientTable showDetailsOnClick={false} />
        </Card>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(ClientSection), "Clients"));
