
import { connect, useSelector, useDispatch } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'
import ConfirmDialog from '../../containers/ConfirmDialog';

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import Select from "../../containers/Form/Select";

import projectAPICall from '../../app/apiCalls/projectAPICall'

import { selectProject } from '../../app/store/actions/projectActions'

import { SITE_NAME } from '../../app/globals'
import { project_delete, project_details, project_save } from '../../app/apiEndpoints';
import InvalidPermissions from '../../containers/InvalidPermissions';
import ContentNotFound from '../../containers/ContentNotFound';

const ProjectForm = (props) => {
    let { projectID } = useParams();

    const [inputValue, setInputValue] = useState({ company: 0, name: "", description: "" });
    const [errors, setErrors] = useState({ company: "", name: "", description: "" });
    const { company, name, description } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const [project, setProject] = useState({})

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const newProject = !project.project_id

    const canEdit = (newProject || parseInt(project?.status) === 0) && isSysadmin

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const dispatch = useDispatch();

    useMemo(() => {
        const getProject = async () => {
            if(parseInt(projectID) > 0) {
                const data = await projectAPICall(project_details, {'projectID': projectID, 'requester': userID, 'company': companyID })
                if(data.project?.project_id) {
                    const values = { company: data.project.company_id, name: data.project.project_name, description: data.project.description }
                    setInputValue(values)
                    setProject(data.project)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setProject({ missing: true })
                }
                setLoading(false)
            }
        }
        console.log(projectID, project)
        if(parseInt(projectID) > 0 && (project === undefined || project === null || !project.project_id) && loading) {
            getProject()
        }
        else if(projectID === "add") {
            setLoading(false)
        }
    }, [projectID, userID, companyID, project, loading])

    const saveProject = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await projectAPICall(project_save, {
            'projectID': project.project_id ? project.project_id : 0, 
            'requester': userID, 
            'company': company, 
            'name': name, 
            'description': description 
        })
        console.log(data)

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        if(data.project?.project_id) projectID = data.project.project_id
        setProject(data.project)
        
        console.log(props)
        setFormMessage("Project has been saved")
        window.history.replaceState(null, SITE_NAME, `/projects/${projectID}`);
        dispatch(selectProject(projectID))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };
    
    const handleError = (field, error) => {
          setErrors((prev) => ({
              ...prev,
              [field]: error,
          }));
    };

    const confirmDeleteProject = (e) => {
        e.preventDefault()
        setShowConfirmDelete(true)
    }

    const deleteProject = async () => {
        const data = await projectAPICall(project_delete, {'projectID': project.project_id, 'requester': userID})
        console.log(data)

        //if successful, delete from project object
        if(!data.success && data.error) {
            setFormMessage(data.error)
            return
        } 

        console.log('delete project')
        setProject({})
        setFormMessage('Project has been deleted')
        
        props.history.push('/projects')
        dispatch(selectProject(0))
    }

    if(projectID === "add" && !isSysadmin)
        return <InvalidPermissions />

    if(project.missing)
        return <ContentNotFound contentType="Project" />

    return (
            <>
            <Form title={!newProject ? "Edit Project" : "Add Project"}  description="" onSubmit={saveProject} >
                { !loading ?
                <>
                <Select
                    value={company}
                    label="Company"
                    name="company"
                    formField="companies"
                    useDefault={true}
                    onChange={handleChange}
                    onError={handleError}
                    validate="numeric|required"
                    colSize="6"
                    readOnly={!canEdit}
                />
    
                <Input
                    type="text"
                    value={name}
                    placeholder="Project Name"
                    label="Project Name"
                    name="name"
                    onChange={handleChange}
                    onError={handleError}
                    validate="required"
                    colSize="6"
                    readOnly={!canEdit}
                />
                <Input
                    type="text"
                    value={description}
                    placeholder="Description"
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    onError={handleError}
                    validate="required"
                    colSize="6"
                    readOnly={!canEdit}
                />
                {canEdit && 
                <FormFooter 
                    formButtons={newProject ? [{title: "Save Project", class: "primary"}] : [{title: "Save Project", class: "primary"}, {title: "Delete Project", class: "danger", onClick: confirmDeleteProject}]} 
                    center={true} 
                    formMessage={formMessage} 
                    hasErrors={hasErrors} /> }
                </>
                : <p>Loading form data...</p> }
            </Form>
        <ConfirmDialog
            show={showConfirmDelete}
            title="Delete Project" message="Are you sure you wish to delete this Project?"
            onClose={() => { setShowConfirmDelete(false) }}
            onConfirm={deleteProject}
        />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectProject: (projectID) => { 
            dispatch(selectProject(projectID))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(pageWithSideMenu(pageContent(withRouter(ProjectForm)), "Project Form"));
