import React, { useRef } from 'react'
import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';

import invoiceAPICall from '../../app/apiCalls/invoiceAPICall';
import { invoice_details } from '../../app/apiEndpoints';

import { useReactToPrint }  from "react-to-print";
import { InvoicePrintView } from './InvoicePrintView';

const InvoicePrint = () => {
    let { invoiceID } = useParams();
    const printRef = useRef()

    const [invoice, setInvoice] = useState({})
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    useMemo(() => {
        const getInvoice = async () => {
            if(invoiceID > 0) {
                const data = await invoiceAPICall(invoice_details, {'invoiceID': invoiceID, 'requester': userID, 'company': companyID })
                setInvoice(data.invoice)

                document.title = `Rude Development Invoice #${data.invoice.invoice_no}`
            }
        }
        getInvoice()
    }, [invoiceID, userID, companyID])

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
      });

    return (
        <div>
            <button onClick={handlePrint}>Print Invoice</button>
            <InvoicePrintView invoice={invoice} ref={printRef} />
        </div>
    )
}

export default connect(state => ({...state}))(InvoicePrint);
