import React, { useState, useEffect } from "react";
import validateInput from "../../app/functions/validateInput"

import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Row, Col, FormGroup, Form, Card } from 'react-bootstrap';

const inputDefaults = {
    colSize: 6
}

const Input = ({ value, label, placeholder, type, name, colSize, validate, useColumns, readOnly, textDisplay, onChange, onError }) => {
    const [error, setError] = useState('')
    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        if(hasLoaded || !value || !onError) return
        if(validate && validate?.length > 0 && (validate === "required" || validate.includes("required"))) {
            if(!value || value.length === 0) {
                onError(name, `${label} is required`)
            }
        }
        setHasLoaded(true)
    }, [hasLoaded, value, validate, name, label, onError])

    const handleChange = (e) => {
        if(!e?.name && (type === "date" || type === "datetime")) {
            e = { target: { name: name, value: new Date(e) } }
        }
        
        //end if no validation
        setError('')
        if(!validate) {
            onChange(e)
            return
        }

        onChange(e)
        var valueToValidate = e.target.value
        if(type === "date") valueToValidate = moment(e.target.value).format('MM/DD/YYYY')
        else if(type === "datetime") valueToValidate = moment(e.target.value).format('MM/DD/YYYY')
        const fieldError = validateInput(label, valueToValidate, validate)
        setError(fieldError)
        if(onError)
            onError(name, fieldError)
    }


    if(!useColumns && !colSize)
        return (
            <FormGroup>
                {label && <label htmlFor={name}>{label}</label>}
                {textDisplay ?
                value
                : type === "date" || type === "datetime" ?
                <DatePicker
                    id={name} 
                    name={name} 
                    showTimeSelect={type === "datetime"}
                    dateFormat={type === "datetime" ? "MM/dd/yyyy HH:mm" : "MM/dd/yyyy"} 
                    placeholder={placeholder} 
                    selected={value} 
                    readOnly={readOnly} 
                    className="form-control" 
                    onChange={handleChange} />
                :        
                <input 
                    id={name} 
                    name={name} 
                    value={value}
                    type={type} 
                    readOnly={readOnly}
                    placeholder={placeholder} 
                    className="form-control" 
                    onChange={handleChange} />
                }
                <Card.Text className="text-danger small">{error}</Card.Text>
            </FormGroup>
        )

    return (
        <FormGroup>
            <Row>
                {label && <Form.Label column sm="3" htmlFor={name}>{label}</Form.Label>}
                <Col md={colSize !== undefined ? colSize : inputDefaults.colSize}>
                    {textDisplay ?
                    value
                    : type === "date" || type === "datetime" ?
                    <DatePicker
                        id={name} 
                        name={name} 
                        showTimeSelect={type === "datetime"}
                        dateFormat={type === "datetime" ? "MM/dd/yyyy HH:mm" : "MM/dd/yyyy"}
                        placeholder={placeholder} 
                        selected={value} 
                        readOnly={readOnly} 
                        className="form-control" 
                        onChange={handleChange}  />
                    :  
                    <input
                        type={type}
                        value={value}
                        name={name}
                        id={name}
                        readOnly={readOnly}
                        className="form-control"
                        placeholder={placeholder}
                        onChange={handleChange}
                    />
                    }
                    <Card.Text className="text-danger small">{error}</Card.Text>
                </Col>
            </Row>
        </FormGroup>
    );
};

export default Input;