
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContentWithDetails from '../../containers/pageContentWithDetails'

import InvoiceTable from './InvoiceTable'
import InvoiceDetails from './InvoiceDetails'
import CardHeader from '../../containers/CardHeader'

import { FaPlus } from "react-icons/fa";
import { Card } from 'react-bootstrap'

const InvoiceSection = (props) => {
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const title = "Client Invoices";
    const subtitle = "Listing of all Client Invoices"
    const headerAction = isSysadmin ? {'path': "/invoices/add", 'icon':FaPlus} : {}

    return (
        <Card>
            <CardHeader title={title} description={subtitle} action={headerAction} />
            <InvoiceTable statusFilter={0} showDetailsOnClick={true} />
        </Card>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContentWithDetails(InvoiceSection, InvoiceDetails), "Invoices"));
