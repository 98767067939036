import { API_KEY, API_URL } from "../apiEndpoints"
import axios from 'axios'

const apisAPICall = async (apiCall, filters) => {
    const formData  = new FormData();
    for(const name in filters) {
        formData.append(`${apiCall.prefix}:${name}`, filters[name]);
    }
    formData.append('API_KEY', API_KEY);
    formData.append('action', apiCall.action);
    
    axios.defaults.headers.post['Content-Type'] ='multipart/form-data';
    return axios.post(`${API_URL}${apiCall.URL}`, formData)
    .then((responseData) => {
        if(responseData.data?.error) return responseData.data
        return (responseData.data?.apis) ? responseData.data?.apis : { 'apis': [] };
    })
    .catch(error => console.warn(error));
}

export default apisAPICall;