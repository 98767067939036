import React, { useState } from 'react';
import { connect } from 'react-redux'

import { SITE_NAME } from '../../app/globals'

import { handleLogin } from '../../app/store/actions/loginActions'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import { Card } from 'react-bootstrap';
import FormFooter from '../../containers/Form/FormFooter';
import loginAPICall from '../../app/apiCalls/loginAPICall';
import { login, forgot_password } from '../../app/apiEndpoints';

function LoginForm(props) {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [errors, setErrors] = useState({email: "", password: "" });
    const [formMessage, setFormMessage] = useState('')
    const [status, setStatus] = useState(0)
    const [hasErrors, setHasErrors] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)

    const handleSubmit = async e => {
        e.preventDefault();
        if(status > 0) return

        let formErrors = []
        setHasErrors(false)
        for (const error of Object.entries(errors)) {
            if(forgotPassword && error[0] === "password") continue
            if(error[1] && error[1].length > 0) formErrors.push(error[1])
        }

        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')
        setStatus(1)

        let data = {}
        if(forgotPassword)
            data = await loginAPICall(forgot_password, { 'email': email });
        else
            data = await loginAPICall(login, { 'email': email, 'password': password });

        if(data.error) {
            setStatus(0)
            setHasErrors(true)
            setFormMessage(data.error)
            return
        }

        if(forgotPassword) {
            if(data.message) setFormMessage(data.message)
            setStatus(2)
        } else {
            const token = data.token
            props.handleLogin(data, token)
        }
      }

    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    const returnToLogin = () => {
        setFormMessage("")
        setStatus(0)
        setForgotPassword(false)
    }

    if(forgotPassword)
    return (
            <Form 
                className="form" 
                onSubmit={handleSubmit} 
                title="Forgot Password?" 
                customBodyClass="card-login" 
                centerHeader={true} >

                <Input type="email" label="Email" name="email" placeholder="Enter email" validate="email|required"
                    onChange={e => setEmail(e.target.value)} onError={handleError} readOnly={status > 0} />

                <FormFooter formButtons={status === 0 ? [{'title': "Submit", 'class': "warning btn-wd"},{'title': "Cancel", 'class': "secondary", onClick: () => setForgotPassword(false) }] : status === 2 ? [{'title': "Back to Login", 'class': "primary btn-wd", onClick: returnToLogin }] : []} center={true} formMessage={formMessage} hasErrors={hasErrors} />
            </Form>
    )
    
    return (
            <Form 
                className="form" 
                onSubmit={handleSubmit} 
                title={SITE_NAME} 
                customBodyClass="card-login" 
                centerHeader={true} 
                formMessage={formMessage}
                footerButton={status === 0 ? {title: "Login", class: "warning btn-wd"} : {}} >

                <Input type="email" label="Email" name="email" placeholder="Enter email" validate="email|required"
                    onChange={e => setEmail(e.target.value)} onError={handleError} readOnly={status > 0} />
                <Input type="password" label="Password" name="password" placeholder="Enter password" validate="required"
                    onChange={e => setPassword(e.target.value)} onError={handleError} readOnly={status > 0} />
                
                <Card.Text className="small text-right"><a onClick={() => setForgotPassword(true)} href="#forgot">Forgot Password?</a></Card.Text>
            </Form>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        handleLogin: (user, token) => { 
            dispatch(handleLogin(user, token))
        }
    }
  }
  
  export default connect(state => ({...state}), mapDispatchToProps)(LoginForm);