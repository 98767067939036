import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import reportAPICall from '../../app/apiCalls/reportAPICall'
import { report_details_from_uri, report_data } from "../../app/apiEndpoints"
import DataTable from "../../containers/DataTable";
import CardHeader from '../../containers/CardHeader'
import ReportOptions from "./ReportOptions"
import { Card } from 'react-bootstrap'
import { tableTypes } from "../../app/settings";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ReportView = (props) => {
    let query = useQuery();

    const [report, setReport] = useState({})
    const [reportData, setReportData] = useState([])
    let { reportURI } = useParams();
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    const startDate = query.get("startDate")
    const endDate = query.get("endDate")
    const reportSource = query.get("source")

    const [dataLoaded, setDataLoaded] = useState(false)

    useEffect(() => {
        const getDetails = async () => {
            if(reportURI.length > 0) {
                const data = await reportAPICall(report_details_from_uri, {'reportURI': reportURI, 'requester': userID, 'company': companyID })
                setReport(data.report)
            }
        }
        getDetails()
    }, [reportURI, userID, companyID])

    useEffect(() => {
        const getData = async () => {
            if(report.report_id > 0 && (parseInt(report.has_date_range) === 0 || (startDate.length > 0 && endDate.length > 0))) {
                const data = await reportAPICall(report_data, {'reportID': report.report_id, 'startDate': startDate, 'endDate': endDate, 'source': reportSource })
                setReportData(data.data)
                setDataLoaded(true)
            }
        }
        if(!dataLoaded)
            getData()
    }, [report, startDate, endDate, reportSource, dataLoaded])

    const tableFields = () => {
        if(reportData.length === 0) return []
        const row = reportData[0]
        const fields = Object.keys(row).map((field) => field)
        return fields
    }

    const tableData = () => {
        console.log("table data")
        if(reportData.length === 0) return []
        const dataRows = []
        reportData.forEach((row, i) => {
            const values = []
            Object.keys(row).forEach((field) => values.push(row[field]))
            dataRows.push({'id': i, 'values': values});
        });
        return dataRows
    }

    const csvData = () => {
        if(reportData.length === 0) return []
        const dataRows = []
        dataRows.push(tableFields())
        reportData.forEach((row, i) => {
            const values = []
            Object.keys(row).forEach((field) => values.push(row[field]))
            dataRows.push(values);
        });
        return dataRows
    }

    const csvFileName = () => {
        if(report.report_name.length === 0) return "Report"
        return `${report.report_name.replaceAll(" ", "_")}.csv`
    }

    return (
        <Card>
            <CardHeader title={report.report_name} description={report.description} />
            {reportData?.length > 0 ?
            <>
            <Card.Body>
            <ReportOptions 
                reportID={report.report_id}
                startDate={startDate}
                endDate={endDate}
                source={reportSource}
                formAction={`/reports/view/${reportURI}`}
                hasDateRange={report.has_date_range}
                csvData={csvData()}
                csvFileName={csvFileName()} />
            </Card.Body>
            <DataTable tableID={reportURI} fieldNames={tableFields()} dataRows={tableData()} tableSettings={tableTypes.Report} />
            </>
            : <Card.Body>Loading report...</Card.Body>}
        </Card>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(ReportView), "Report View"));
