import { useParams } from "react-router-dom";
import { connect, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'

import CardHeader from '../../containers/CardHeader'

import toolsAPICall from '../../app/apiCalls/toolsAPICall'
import { tool_details_from_uri } from "../../app/apiEndpoints"
import { Card, Row, Col } from "react-bootstrap";

const ToolsAdmin = (props) => {
    let { toolURI } = useParams();
    const [tool, setTool] = useState({})
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)

    useEffect(() => {
        const getDetails = async () => {
            if(toolURI.length > 0) {
                const data = await toolsAPICall(tool_details_from_uri, {'toolURI': toolURI, 'requester': userID, 'company': companyID })
                setTool(data.tool)
            }
        }
        getDetails()
    }, [toolURI, userID, companyID])


    if(!tool) return <></>

    return (
        <>
        <Row>
            <Col md={6}>
                <Card>
                    <Card.Body>
                        <CardHeader title={tool.tool_name} description={tool.description} />
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <Card>

                </Card>
            </Col>
        </Row>
        </>
    )
}

export default connect(state => ({...state}))(pageWithSideMenu(pageContent(ToolsAdmin), "Tools Admin"));
