import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import invoiceAPICall from '../../app/apiCalls/invoiceAPICall'

import { FaEdit } from "react-icons/fa";
import CardHeader from '../../containers/CardHeader';
import CardSectionTitle from '../../containers/CardSectionTitle';
import Button from '../../containers/Form/Button';

import { doInvoiceUpdate } from '../../app/store/actions/invoiceActions'
import { invoice_details, invoice_update_status } from '../../app/apiEndpoints';
import { Card, Row, Col, Accordion } from 'react-bootstrap';

const InvoiceDetails = (props) => {
    const [invoice, setInvoice] = useState({})
    const invoiceID = useSelector((state) => state.currentInvoiceID)
    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin

    const headerAction = canEdit ? {'path': `/invoices/${invoiceID}`, 'icon':FaEdit} : {}

    useMemo(() => {
        const getInvoice = async () => {
            if(invoiceID > 0) {
                const data = await invoiceAPICall(invoice_details, {'invoiceID': invoiceID, 'requester': userID, 'company': companyID })
                setInvoice(data.invoice)
            }
        }
        getInvoice()
    }, [invoiceID, userID, companyID])

    const invoiceStatus = () => {
        let status = '<span class="text-secondary">Pending</span>';
        if(parseInt(invoice.status) === 2) status = '<span class="text-success">Paid</span>';
        else if(parseInt(invoice.status) === 1) status = '<span class="text-warning">Waiting Payment</span>';
        return status
    }

    const markSent = async () => {
        console.log('sent');
        
        //call API to update
        const data = await invoiceAPICall(invoice_update_status, {'invoiceID': invoiceID, 'status': 1, 'requester': userID })

        //if successful, delete from project object
        if(!data.success || data.error) {
            alert(data.error)
            return
        } 
        invoice.status = 1
        props.doInvoiceUpdate(invoice)
    }

    const markPaid = async () => {
        console.log('paid');
        
        //call API to update
        const data = await invoiceAPICall(invoice_update_status, {'invoiceID': invoiceID, 'status': 2, 'requester': userID })

        //if successful, delete from project object
        if(!data.success || data.error) {
            alert(data.error)
            return
        } 
        invoice.status = 2
        props.doInvoiceUpdate(invoice)
    }

    if(!invoiceID) return <></>

    return (
        <Card>
            <CardHeader title={`Invoice #${invoice.invoice_no}`} description={invoice.company_name} action={headerAction} />
            <Card.Body>
                <Accordion>
                    <Card>
                        <CardSectionTitle title="About" />
                        <Card.Body>
                            Hours: {invoice.total_hours}<br/>
                            Billed: {`$${invoice.total_billed}`}
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Status" />
                        <Card.Body>
                            <Row>
                                <Col md={8} dangerouslySetInnerHTML={{ __html: invoiceStatus() }} />
                                <Col md={4}>
                                    {canEdit && parseInt(invoice.status) === 0 && <Button title="Mark Sent" className="info btn-sm" onClick={markSent} />}
                                    {canEdit && parseInt(invoice.status) === 1 && <Button title="Mark Paid" className="info btn-sm" onClick={markPaid} />}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <CardSectionTitle title="Projects" />
                        <Card.Body>
                            {invoice.projects?.length > 0 ?
                            invoice.projects.map((project,p) => (
                                <p key={p}>{`${project.project_name} (${project.hours} hours)`}</p>
                            )) : null}
                        </Card.Body>
                    </Card>
                </Accordion>
            </Card.Body>
        </Card>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        doInvoiceUpdate: (invoice) => { 
            dispatch(doInvoiceUpdate(invoice))
        }
    }
  }

export default connect(state => ({...state}), mapDispatchToProps)(InvoiceDetails)