
import { connect, useSelector } from 'react-redux'
import { useState, useMemo } from 'react'
import { useParams, withRouter } from 'react-router-dom';

import pageWithSideMenu from '../../containers/pageWithSideMenu'
import pageContent from '../../containers/pageContent'
import CardHeader from '../../containers/CardHeader';
import UsersTable from '../Users/UsersTable';

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";

import clientAPICall from '../../app/apiCalls/clientAPICall'

import { SITE_NAME } from '../../app/globals'
import { client_details, client_save } from '../../app/apiEndpoints';
import InvalidPermissions from "../../containers/InvalidPermissions";
import ContentNotFound from '../../containers/ContentNotFound';
import { Card } from 'react-bootstrap';

const ClientForm = (props) => {
    let { clientID } = useParams();

    const [client, setClient] = useState({})
    const [inputValue, setInputValue] = useState({ name: "", key: "", domain: "", hourlyRate: "100.00", address1: "", address2: "", phone: "", email: "" });
    const [errors, setErrors] = useState({ name: "", key: "", domain: "", hourlyRate: "", address1: "", address2: "", phone: "", email: "" });
    const { name, key, domain, hourlyRate, address1, address2, phone, email } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)
    const [loading, setLoading] = useState(true)

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)
    const canEdit = isSysadmin
    const newClient = !client.company_id

    useMemo(() => {
        const getDetails = async () => {
            if(parseInt(clientID) > 0) {
                const data = await clientAPICall(client_details, {'clientID': clientID, 'requester': userID, 'company': companyID })

                if(data.client?.company_name) {
                    const values = { name: data.client.company_name, key: data.client.company_key, domain: data.client.alias_domain, hourlyRate: data.client.hourly_rate, address1: data.client.address_line_1, address2: data.client.address_line_2, phone: data.client.contact_phone, email: data.client.contact_email }
                    setClient(data.client)
                    setInputValue(values)
                } else if(data.error) {
                    setFormMessage(data.error)
                    setHasErrors(true)
                }
                if(data.found && data.found === "0") {
                    setClient({ missing: true })
                }
                setLoading(false)
            }
        }
        if(parseInt(clientID) > 0 && (client === undefined || client === null || !client.company_id) && loading) {
            getDetails()
        } else if(clientID === "add") {
            setLoading(false)
        }
    }, [clientID, userID, companyID, client, loading])

    const saveClient = async (e) => {
        e.preventDefault()
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await clientAPICall(client_save, {
            'clientID': client.company_id ? client.company_id : 0, 
            'requester': userID, 
            'name': name, 
            'key': key, 
            'domain': domain, 
            'hourlyRate': hourlyRate, 
            'address_line_1': address1, 
            'address_line_2': address2, 
            'contact_email': email, 
            'contact_phone': phone 
        })

        //if successful, delete from project object
        if(data.error) {
            setFormMessage(data.error)
            setHasErrors(true)
            return
        } 

        if(data.client?.company_id) clientID = data.client.company_id
        setClient(data.client)
        
        setFormMessage("Client has been saved")
        window.history.replaceState(null, SITE_NAME, `/clients/${clientID}`);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue((prev) => ({
          ...prev,
          [name]: value,
        }));
    };

    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    if(!isSysadmin) 
        return (
            <InvalidPermissions />
        )

    if(client.missing)
        return <ContentNotFound contentType="Client" />

    return (
        <>
        <Form title={!newClient ? "Edit Client" : "Add Client"}  description="" onSubmit={saveClient} >
            { !loading ?
            <>
            <Input
                type="text"
                value={name}
                placeholder="Client Name"
                label="Client Name"
                name="name"
                onChange={handleChange}
                onError={handleError}
                validate="company|required"
                colSize="6"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={key}
                placeholder="Client Key"
                label="Client Key"
                name="key"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="3"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={domain}
                placeholder="Alias Domain"
                label="Alias Domain"
                name="domain"
                onChange={handleChange}
                onError={handleError}
                colSize="3"
                readOnly={!canEdit}
            />
            <Input
                type="number"
                value={hourlyRate}
                placeholder="Hourly Rate"
                label="Hourly Rate"
                name="hourlyRate"
                onChange={handleChange}
                onError={handleError}
                colSize="3"
                validate="decimal|required"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={address1}
                placeholder="Address Line 1"
                label="Address Line 1"
                name="address1"
                onChange={handleChange}
                onError={handleError}
                colSize="6"
                validate="address"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={address2}
                placeholder="Address Line 2"
                label="Address Line 2"
                name="address2"
                onChange={handleChange}
                onError={handleError}
                colSize="6"
                validate="address"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={email}
                placeholder="Contact Email"
                label="Contact Email"
                name="email"
                onChange={handleChange}
                onError={handleError}
                colSize="3"
                validate="email"
                readOnly={!canEdit}
            />
            <Input
                type="text"
                value={phone}
                placeholder="Contact Phone"
                label="Contact Phone"
                name="phone"
                onChange={handleChange}
                onError={handleError}
                colSize="3"
                validate="phone"
                readOnly={!canEdit}
            />
            { canEdit &&
            <FormFooter 
                formButton={{title: "Save Client", class: "primary"}} 
                center={true} 
                formMessage={formMessage} 
                hasErrors={hasErrors} /> }
            </>
            : <p>Loading form data...</p> }
        </Form>
        {!newClient &&
        <Card>
            <CardHeader title="Client Users" description="List of Client's current users" />
            <UsersTable showDetailsOnClick={false} filter={clientID} />
        </Card> }
        </>
    )
}


export default connect(state => ({...state}))(pageWithSideMenu(pageContent(withRouter(ClientForm)), "Client Form"));
