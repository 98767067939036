import React, { useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from 'react-redux'

import pageWithSideMenu from "../../containers/pageWithSideMenu";
import pageContentWithDetails from "../../containers/pageContentWithDetails";
import ProfileSide from "./ProfileSide"

import siteAPICall from "../../app/apiCalls/siteAPICall"
import { profile_update } from "../../app/apiEndpoints";
import { updateUser } from '../../app/store/actions/loginActions'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import { Card } from "react-bootstrap";

function Profile() {
    const [inputValue, setInputValue] = useState({ company: "", firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
    const [errors, setErrors] = useState({ firstName: "", lastName: "", email: "", password: "", confirmPassword: "" });
    const { company, firstName, lastName, email, password, confirmPassword } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)

    const user = useSelector((state) => state.user)
    const userID = useSelector((state) => state.user.id)
    const dispatch = useDispatch();

    useMemo(() => {
        if(user !== undefined) {
            const userValues = { company: user.company.name, firstName: user.firstName, lastName: user.lastName, email: user.email, password: "", confirmPassword: "" }
            setInputValue(userValues)
        }
    }, [user])

    const submit = async (e) => {
        e.preventDefault();
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        // Validate passwords
        if(password?.length > 0) {

            if(!confirmPassword || confirmPassword?.length === 0) {
                formErrors.push("You must confirm your New Password")
            }
            else if(password !== confirmPassword) {
                formErrors.push("Your Confirm Password does not match your New Password")
            }
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await siteAPICall(profile_update, { 'userID': userID, 'firstName': firstName, 'lastName': lastName, 'email': email, 'password': password || "" })

        //if successful, delete from project object
        if(data.error) {
            setHasErrors(true)
            setFormMessage(data.error)
            return
        } 

        setFormMessage("Your Profile has been updated")
        dispatch(updateUser(firstName, lastName, email))
    }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    return (
        <Form title="Edit Profile" description="Keep your information up-to-date" onSubmit={submit} >
        <Card.Title>Account</Card.Title>
            <Input
                type="text"
                value={company}
                placeholder="Company"
                label="Company"
                name="company"
                textDisplay={true}
                colSize="9"
            />
            <Input
                type="text"
                value={firstName}
                placeholder="First Name"
                label="First Name"
                name="firstName"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="9"
            />
            <Input
                type="text"
                value={lastName}
                placeholder="Last Name"
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                onError={handleError}
                validate="name|required"
                colSize="9"
            />
            <Input
                type="text"
                value={email}
                placeholder="Your Email"
                label="Email"
                name="email"
                onChange={handleChange}
                onError={handleError}
                validate="email|required"
                colSize="9"
            />
            <Card.Title className="mt-5">Password</Card.Title>
            <Input
                type="password"
                value={password}
                placeholder="New Password"
                label="New Password"
                name="password"
                onChange={handleChange}
                onError={handleError}
                colSize="9"
            />
            <Input
                type="password"
                value={confirmPassword}
                placeholder="Confirm Password"
                label="Confirm Password"
                name="confirmPassword"
                onChange={handleChange}
                onError={handleError}
                colSize="9"
            />

            <FormFooter formButton={{'title': "Update Profile", 'class': "primary"}} center={true} formMessage={formMessage} hasErrors={hasErrors} />
        </Form>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (firstName, lastName, email) => { 
            dispatch(updateUser(firstName, lastName, email))
        }
    }
  }

export default connect(state => ({...state}), mapDispatchToProps)(pageWithSideMenu(pageContentWithDetails(Profile, ProfileSide), "Profile"));
