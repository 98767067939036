import React from 'react';
import { Row, Col } from "react-bootstrap";


const pageContent = (WrappedComponent) => {
    class ContentWrapper extends React.Component {
        render () {
            return (
                <div className="content">
                    <div className="container-fluid">
                        <Row>
                            <Col>
                                <WrappedComponent /> 
                            </Col>
                        </Row>
                    </div>
                </div>
            )
        }
    }

    return ContentWrapper
}

export default pageContent;
