import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import reportAPICall from '../../app/apiCalls/reportAPICall'
import { selectReport } from '../../app/store/actions/reportActions'
import { reports_list } from '../../app/apiEndpoints'

const ReportTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "report"
    const tableFields = ["Name", "Client", "Status"]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentReportID)

    const onClick = (e) => {
        if(!props.showDetailsOnClick) return;
        let rowID = e.currentTarget.id
        rowID = rowID.replace(`${tableID}_`, "")
        props.selectReport(rowID)
    }

    useMemo(() => {
        const getData = async () => {
            const data = await reportAPICall(reports_list, {'requester': userID, 'company': companyID })
            setData(data.reports)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.report_id, 'values': [
                (props.showDetailsOnClick ? row.report_name : `<a href="/reports/${row.uri}">${row.report_name}</a>`),
                row.company_name,
                parseInt(row.isactive) === 1 ? "Active" : "Inactive"
            ]});
        });
        return rows
    }


    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} onClick={onClick} currentRowID={rowID} />
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        selectReport: (id) => { 
            dispatch(selectReport(id))
        }
    }
  }

export default connect(state => ({...state}),mapDispatchToProps)(ReportTable)