import React from 'react';
import Header from '../components/Global/Header'
import TopMenu from '../components/Global/TopMenu'
import SideMenu from '../components/Global/SideMenu'
import Footer from '../components/Global/Footer'

const pageWithSideMenu = (WrappedComponent, pageTitle) => {
    class SectionWrapper extends React.Component {
        render () {
            return (
            <>
            <div className="wrapper">
                <Header/>
                <SideMenu />
                <div className="main-panel">
                <TopMenu title={pageTitle} /> 
                <WrappedComponent />
                </div>
            </div> 
            <Footer />
            </>
            )
        }
    }

    return SectionWrapper
}

export default pageWithSideMenu;