
import DashboardShortcuts from './DashboardShortcuts';
import { Col, Row } from 'react-bootstrap';
import DashboardClientStatus from './DashboardClientStatus';

const UserDashboard = () => {

    return (
        <>
        <Row>
            <Col md={6}>
                <DashboardClientStatus />
            </Col>
            <Col md={6}>
                <DashboardShortcuts />
            </Col>
        </Row>
        </>
    )
}

export default UserDashboard;
