import { Card } from "react-bootstrap";
import CardHeader from "../CardHeader";
import FormFooter from "./FormFooter";

const Form = ({ title, description, headerAction, onSubmit, children, customBodyClass, centerHeader, footerButton, noCard, formMessage}) =>  {
    if(noCard)
        return (
            <form className="form-horizontal" onSubmit={onSubmit}>
                <CardHeader title={title} description={description} centerHeader={centerHeader} action={headerAction} />
                <Card.Body className="card-body">
                    {children}
                    <Card.Text className="text-danger p-3" dangerouslySetInnerHTML={{ __html: (formMessage) }} />
                </Card.Body>
                { footerButton && <FormFooter formButton={footerButton} /> }
            </form>
        )

    return (
        <>
        <form className="form-horizontal" onSubmit={onSubmit}>
            <Card className={customBodyClass}>
                <CardHeader title={title} description={description} centerHeader={centerHeader} action={headerAction} />
                <Card.Body className="card-body">
                    {children}
                    <Card.Text className="text-danger p-3" dangerouslySetInnerHTML={{ __html: (formMessage) }} />
                </Card.Body>
                { footerButton && <FormFooter formButton={footerButton} /> }
            </Card>
        </form>
        </>
    )

}

export default Form;