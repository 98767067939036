import { Route } from 'react-router-dom';

function RouteWithSubRoutes(route) {

  if(route.child) 
    return <Route path={route.path} children={<route.child />} />

  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default RouteWithSubRoutes;