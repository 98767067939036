import DashboardSection from "../components/Dashboard/DashboardSection";
import Contact from "../components/Contact";
import Profile from "../components/Profile/Profile";
import Settings from "../components/Profile/Settings";

import ProjectSection from "../components/Projects/ProjectSection";
import ProjectForm from "../components/Projects/ProjectForm";

import APISection from "../components/APIs/APISection";
import APIForm from "../components/APIs/APIForm";

import BannerSection from "../components/Banners/BannerSection";
import BannerView from "../components/Banners/BannerView";
import BannerForm from "../components/Banners/BannerForm";

import LanderSection from "../components/Landers/LanderSection";

import ClientSection from "../components/Clients/ClientSection";
import ClientForm from "../components/Clients/ClientForm";

import InvoiceSection from "../components/Invoices/InvoiceSection"
import InvoicePrint from "../components/Invoices/InvoicePrint";
import InvoiceForm from "../components/Invoices/InvoiceForm";

import ReportSection from "../components/Reports/ReportSection";
import ReportView from "../components/Reports/ReportView";
import ReportForm from "../components/Reports/ReportForm";

import UsersSection from "../components/Users/UsersSection";
import UsersForm from "../components/Users/UsersForm";

import ToolsSection from "../components/Tools/ToolsSection";
import ToolsAdmin from "../components/Tools/ToolsAdmin";
import ToolsForm from "../components/Tools/ToolsForm";
import TJPauseTool from "../components/Tools/Unique/TJPauseTool";
import TJBulkBidTool from "../components/Tools/Unique/TJBulkBidTool";
import CamModelTool from "../components/Tools/Unique/CamModelTool";
import CamModelLinkTool from "../components/Tools/Unique/CamModelLinkTool";
import CamModelLinkToolExoclick from "../components/Tools/Unique/CamModelLinkToolExoclick";
import TJBidSchedulerTool from "../components/Tools/Unique/TJBidSchedulerTool";
import ReportDownload from "../components/Reports/ReportDownload";
import MCLTwitterTool from "../components/Tools/Unique/MCLTwitterTool";
import SubpidTool from "../components/Tools/Unique/SubpidTool";
import LanderDashboard from "../components/Tools/Unique/LanderDashboard";
import SubpidExclusionTool from "../components/Tools/Unique/SubpidExclusionTool";
import SubpidCapTool from "../components/Tools/Unique/SubpidCapTool";
import SubpidFallbackTool from "../components/Tools/Unique/SubpidFallbackTool";

export const routes = [
    {
      path: "/Contact",
      component: Contact
    },
    {
      path: "/Profile",
      component: Profile
    },
    {
      path: "/Settings",
      component: Settings
    },
    {
      path: "/projects/:projectID",
      component: ProjectForm
    },
    {
      path: "/projects",
      component: ProjectSection
    },
    {
      path: "/apis/:apiID",
      component: APIForm
    },
    {
      path: "/apis",
      component: APISection
    },
    {
      path: "/banners/view/:bannerID",
      component: BannerView
    },
    {
      path: "/banners/:bannerID",
      component: BannerForm
    },
    {
      path: "/banners",
      component: BannerSection,
    },
    {
      path: "/landers/view/:landerID",
      component: BannerView
    },
    {
      path: "/landers/:landerID",
      component: BannerForm
    },
    {
      path: "/landers",
      component: LanderSection,
    },
    {
      path: "/tools/admin/tj-bulk-bids",
      component: TJBulkBidTool
    },
    {
      path: "/tools/admin/tj-bulk-pause",
      component: TJPauseTool
    },
    {
      path: "/tools/admin/tj-bids",
      component: TJBidSchedulerTool
    },
    {
      path: "/tools/admin/mcl-tweets",
      component: MCLTwitterTool
    },
    {
      path: "/tools/admin/cam-models",
      component: CamModelTool
    },
    {
      path: "/tools/admin/cams-campaign-links",
      component: CamModelLinkTool
    },
    {
      path: "/tools/admin/cams-campaign-links-exoclick",
      component: CamModelLinkToolExoclick
    },
    {
      path: "/tools/admin/subpids",
      component: SubpidTool
    },
    {
      path: "/tools/admin/lp-dashboard",
      component: LanderDashboard
    },
    {
      path: "/tools/admin/subpid-exclusions",
      component: SubpidExclusionTool
    },
    {
      path: "/tools/admin/subpid-caps",
      component: SubpidCapTool
    },
    {
      path: "/tools/admin/subpid-fallbacks",
      component: SubpidFallbackTool
    },
    {
      path: "/tools/admin/:toolURI",
      component: ToolsAdmin
    },
    {
      path: "/tools/:toolID",
      component: ToolsForm
    },
    {
      path: "/tools",
      component: ToolsSection
    },
    {
      path: "/reports/export/:reportURI",
      component: ReportDownload
    },
    {
      path: "/reports/view/:reportURI",
      component: ReportView
    },
    {
      path: "/reports/:reportID",
      component: ReportForm
    },
    {
      path: "/reports",
      component: ReportSection
    },
    {
      path: "/invoices/print/:invoiceID",
      component: InvoicePrint
    },
    {
      path: "/invoices/:invoiceID",
      component: InvoiceForm
    },
    {
      path: "/invoices",
      component: InvoiceSection
    },
    {
      path: "/clients/:clientID",
      component: ClientForm
    },
    {
      path: "/clients",
      component: ClientSection
    },
    {
      path: "/users/:userID",
      component: UsersForm
    },
    {
      path: "/users",
      component: UsersSection
    },
    {
      path: "/",
      component: DashboardSection
    }
  ];