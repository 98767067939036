import { useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'

import DataTable from '../../containers/DataTable'

import bannerAPICall from '../../app/apiCalls/bannerAPICall'
import { banners_list } from '../../app/apiEndpoints'

const BannerTable = (props) => {
    const [data, setData] = useState([])

    const tableID = "banner"
    const tableFields = ["Name", "Client", "Width", "Height", "URL", ""]

    const userID = useSelector((state) => state.user.id)
    const companyID = useSelector((state) => state.user.company.id)
    const rowID = useSelector((state) => state.currentBannerID)
    const isSysadmin = useSelector((state) => state.user.isSysadmin)

    useMemo(() => {
        const getData = async () => {
            const data = await bannerAPICall(banners_list, {'requester': userID, 'company': companyID })
            setData(data.banners)
        }
        getData()
    }, [userID, companyID])

    const tableData = () => {
        if(data.length === 0) {
            return [];
        } 
        let rows = [];
        data.forEach(row => {
            rows.push({'id': row.banner_id, 'values': [
                (props.showDetailsOnClick ? row.name : `<a href="/banners/${row.banner_id}">${row.name}</a>`),
                row.company_name,
                row.width,
                row.height,
                row.url,
                `<a href="/banners/view/${row.banner_id}/" class="btn btn-primary btn-sm">View</a> ${isSysadmin ? `<a href="/banners/${row.banner_id}/" class="btn btn-primary btn-sm">Edit</a>` : ''}`
            ]});
        });
        return rows
    }

    return (
        <>
        <DataTable hover={true} tableID={tableID} fieldNames={tableFields} dataRows={tableData()} currentRowID={rowID} />
        </>
    )
}


export default connect(state => ({...state}))(BannerTable)