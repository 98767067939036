import React, { useState, useMemo } from "react";
import { connect, useSelector, useDispatch } from 'react-redux'

import pageWithSideMenu from "../../containers/pageWithSideMenu";
import pageContentWithDetails from "../../containers/pageContentWithDetails";
import ProfileSide from "./ProfileSide"

import siteAPICall from "../../app/apiCalls/siteAPICall"
import { settings_update } from "../../app/apiEndpoints";
import { updateSettings } from '../../app/store/actions/loginActions'

import Form from '../../containers/Form/Form'
import Input from '../../containers/Form/Input';
import FormFooter from "../../containers/Form/FormFooter";
import { tableTypes } from "../../app/settings";
import { Card } from "react-bootstrap";

function Settings() {
    const [inputValue, setInputValue] = useState({ site_pagination: 0, report_pagination: 0, tool_pagination: 0 });
    const [errors, setErrors] = useState({ site_pagination: "", report_pagination: "", tool_pagination: "" });
    const { site_pagination, report_pagination, tool_pagination } = inputValue;
    const [formMessage, setFormMessage] = useState('')
    const [hasErrors, setHasErrors] = useState(false)

    const settings = useSelector((state) => state.user.settings)
    const user = useSelector((state) => state.user)
    const userID = useSelector((state) => state.user.id)
    const dispatch = useDispatch();

    useMemo(() => {
        if(user !== undefined) {
            const userValues = { site_pagination: settings.pagination.site, report_pagination: settings.pagination.report, tool_pagination: settings.pagination.tool }
            setInputValue(userValues)
        } else {
            const userValues = { site_pagination: tableTypes.General.pageSize, report_pagination: tableTypes.Report.pageSize, tool_pagination: tableTypes.Tool.pageSize }
            setInputValue(userValues)
        }
    }, [user, settings])

    const submit = async (e) => {
        e.preventDefault();
        let formErrors = []
        setHasErrors(false)
        for (const field of Object.entries(errors)) {
            const error = field[1]
            if(error.length > 0) formErrors.push(error)
        }
        if(formErrors.length > 0) { 
            setHasErrors(true)
            setFormMessage(formErrors.join('<br/>'))
            return
        }
        setFormMessage('')

        const data = await siteAPICall(settings_update, { 'userID': userID, 'site_pagination': site_pagination, 'report_pagination': report_pagination, 'tool_pagination': tool_pagination })

        //if successful, delete from project object
        if(data.error) {
            setHasErrors(true)
            setFormMessage(data.error)
            return
        } 

        setFormMessage("Your Settings have been updated")
        dispatch(updateSettings({'pagination': {'site': site_pagination, 'report': report_pagination, 'tool': tool_pagination}}))
    }
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setInputValue((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleError = (field, error) => {
        setErrors((prev) => ({
            ...prev,
            [field]: error,
        }));
    };

    return (
        <Form title="Edit Settings" description="Manage your Ruudev site settings" onSubmit={submit} >
            <Card.Title>Pagination</Card.Title>
            <Input
                type="number"
                value={site_pagination}
                placeholder="General Page Size"
                label="General Page Size"
                name="site_pagination"
                onChange={handleChange}
                onError={handleError}
                validate="number|required"
                colSize="3"
            />
            <Input
                type="number"
                value={report_pagination}
                placeholder="Report Page Size"
                label="Report Page Size"
                name="report_pagination"
                onChange={handleChange}
                onError={handleError}
                validate="number|required"
                colSize="3"
            />
            <Input
                type="number"
                value={tool_pagination}
                placeholder="Tool Page Size"
                label="Tool Page Size"
                name="tool_pagination"
                onChange={handleChange}
                onError={handleError}
                validate="number|required"
                colSize="3"
            />

            <FormFooter formButton={{'title': "Update Settings", 'class': "primary"}} center={true} formMessage={formMessage} hasErrors={hasErrors} />
        </Form>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateSettings: (settings) => { 
            console.log('test')
            dispatch(updateSettings(settings))
        }
    }
  }

export default connect(state => ({...state}), mapDispatchToProps)(pageWithSideMenu(pageContentWithDetails(Settings, ProfileSide), "Profile"));
