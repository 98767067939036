import React, { useState, useEffect } from "react";
import validateInput from "../../app/functions/validateInput"

import siteAPICall from "../../app/apiCalls/siteAPICall"
import { form_fields } from "../../app/apiEndpoints";

import { Row, Col, FormGroup, Form, Card } from 'react-bootstrap';

const selectDefaults = {
    colSize: 6
}

const Select = ({ value, label, name, colSize, validate, useColumns, readOnly, formField, formValues, useDefault, onChange, onError }) => {
    const [options, setOptions] = useState([])
    const [error, setError] = useState('')
    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        if(hasLoaded || !value || !onError) return
        if(validate && validate?.length > 0 && (validate === "required" || validate.includes("required"))) {
            if(!value || value.length === 0) {
                onError(name, `${label} is required`)
            }
        }
        setHasLoaded(true)
    }, [hasLoaded, value, validate, name, label, onError])

    useEffect(() => {
        if(formValues?.length > 0) {
            setOptions(formValues)
            return
        }
        else if(formField === "yesno") {
            const data = [{ID: 0, NAME: "No"}, {ID: 1, NAME: "Yes"}]
            setOptions(data)
            return
        }

        const getFields = async () => {
            const data = await siteAPICall(form_fields, {'field': formField})
            if(data?.options) setOptions(data.options)
        }
        getFields()
    }, [formField, formValues])


    const handleChange = (e) => {
        //end if no validation
        setError('')
        if(!validate) {
            onChange(e)
            return
        }

        onChange(e)
        const fieldError = validateInput(label, e.target.value, validate)
        setError(fieldError)
        if(onError)
            onError(name, fieldError)
    }

    if(!useColumns && !colSize)
        return (
            <FormGroup>
                {label && <Form.Label column sm="3">{label}</Form.Label>}
                <select 
                    id={name} 
                    name={name} 
                    value={value}
                    disabled={readOnly}
                    className="form-control"  
                    onChange={handleChange}>
                    {useDefault && <option value="0">-- Select a {label} --</option>}
                    {options.map((option, o) => (
                        <option value={option.ID} key={`${name}_${o}`}>{option.NAME}</option>
                    ))}
                </select>
                <Card.Text className="text-danger small">{error}</Card.Text>
            </FormGroup>
        )

    return (
        <FormGroup>
            <Row>
                {label && <Form.Label column sm="3" htmlFor={name}>{label}</Form.Label>}
                <Col md={colSize !== undefined ? colSize : selectDefaults.colSize}>
                    <select 
                        id={name} 
                        name={name} 
                        value={value}
                        disabled={readOnly}
                        className="form-control"  
                        onChange={handleChange}>
                        {useDefault && <option value="0">-- Select a {label} --</option>}
                        {options.map((option, o) => ( 
                            <option value={option.ID} key={`${name}_${o}`}>{option.NAME}</option>
                        ))}
                    </select>
                    <Card.Text className="text-danger small">{error}</Card.Text>
                </Col>
            </Row>
        </FormGroup>
    );
};

export default Select;