import LoginForm from './LoginForm';
import loginPageContent from "../../containers/loginPageContent";


function LoginPage() {

  return (
    <>
      <LoginForm />
    </>
  );
}

export default loginPageContent(LoginPage);